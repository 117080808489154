<template>
  <div>
    <div style="text-align: center; position: relative;">
      <img alt="ReadOwl" src="./assets/logo.png" class="logo"><br>
      
      <div style="height: 90px; max-width: 80vw; margin: 0 auto;">
      <b>E-book reader with Spritz!</b><br>
      <i><a href="https://readmei.com" target="_readmei">ReadMe!</a> will drop support for Spritz soon.. so get ReadOwl now!</i>
      </div>
      <a href="https://apps.apple.com/en/app/readowl-spritz/id6473507149" target="_readowl"><img src="./assets/appstore.png"></a>
    </div>
    <div class="footer_div">
        ReadOwl &copy; 2023-2024 - 
        <a href="/privacy.txt" target="_new">privacy policy</a> - 
        <a href="/terms.txt" target="_new">terms and conditions</a>
        <div style="height: 10px"></div>
    </div>    
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  body {
    margin: 0px !important;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.logo {
  max-width: 30vw;
  min-width: 300px;
}

.footer_div{
    position: absolute;
    bottom: 10px;
    width: 100%;
}

@media screen and (max-height: 568px) {
    .footer_div {
      position: relative !important;
      margin-top: 20px !important;
    }
}


a:hover > img {
  transform: scale(0.9);
}
img {
  border: none;
  transform: scale(1.0);
  transition: all 0.3s;
}
a {
  text-decoration: none;
  color: #6b6b6b;
}

a:hover {
  color: #404040;
}

</style>
